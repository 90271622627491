<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<a-row>
					<a-form-item class="ui-form__item" name="organizationId" label="影院组织">
						<a-select placeholder="请选择" v-model:value="formState.organizationId" style="width: 180px;" @change="(id) => getAllCinemaList(id, false)">
							<!-- <a-select-option :value="0">全部</a-select-option> -->
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="cinemaId" label="所属影院">
						<a-select placeholder="请选择" v-model:value="formState.cinemaId" style="width: 180px;">
							<a-select-option :value="0">全部</a-select-option>
							<a-select-option v-for="item in cinemaAllList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item class="ui-form__item" name="holiday" label="假期名称">
						<a-input v-model:value="formState.holiday" placeholder="请输入假期名称"></a-input>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button v-permission="['ciname_policy_holiday_add']" type="primary" style="margin-right: 10px" @click="onAdd">添加</a-button>
						<a-button v-permission="['ciname_policy_holiday_delete']" :disabled="selectedRowKeys.length === 0" danger @click="onBatchDelete">批量删除</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right;">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>
			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" 
					rowKey="id"
					:rowSelection="{ selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll }"
					:dataSource="list"
				>
					<template #bodyCell="{ column, record, index }">
						<template v-if="column.key === 'time'">
							{{ transDateTime(record.startTime, 1) }} ~ {{ transDateTime(record.endTime, 1) }}
						</template>
						<template v-if="column.key === 'createdTime'">
							{{ transDateTime(record.createTime) }}
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button @click.prevent>操作 <Icon icon="DownOutlined"></Icon>
								</a-button>
								<template #overlay>
									<a-menu>
										<div v-permission="['ciname_policy_holiday_delete']" @click="onDelete(record)">
											<a-menu-item>
												删除
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
			
		</a-spin>
		
		<a-modal v-model:visible="showModal" title="添加公共假期" width="700px">
			<template #footer>
				<a-button @click="showModal = false">取消</a-button>
				<a-button type="primary" @click="onSubmit(true)">提交</a-button>
				<!-- <a-button type="primary" @click="onSubmit(false)">添加并继续</a-button> -->
			</template>
			<a-spin :spinning="loading">
				<a-form ref="addForm" scrollToFirstError :model="modelRef" name="addForm">
					<a-form-item label="影院组织" name="organizationId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select v-model:value="modelRef.organizationId" placeholder="请选择影院组织" @change="(id) => getAllCinemaList(id, true)">
							<a-select-option v-for="item in organizationList" :vlaue="item.id" :key="item.id"> {{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="所属影院" name="cinemaId" :rules="[{ required: true, message: '必选项不允许为空' }]">
						<a-select v-model:value="modelRef.cinemaId" placeholder="请选择所属影院">
							<a-select-option v-for="item in cinemaListFormAdd" :value="item.id" :key="item.id">{{ item.name }}</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="假期名称" name="holiday" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-input v-model:value="modelRef.holiday" placeholder="请输入假期名称"></a-input>
					</a-form-item>
					
					<a-form-item label="假期范围" name="time" :rules="[{ required: true, message: '必填项不允许为空' }]">
						<a-range-picker v-model:value="modelRef.time"></a-range-picker>
					</a-form-item>
				</a-form>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import {
		Icon
	} from '@/components/icon/icon.js';
	import { getOrganizationList, getCinemaList } from '@/service/modules/cinema.js';
	import { getHolidayPolicyList, saveHolidayPolicy, deleteHolidayPolicy, batchDeleteHolidayPolicy } from '@/service/modules/cinema.js';
	export default {
		components: {
			Icon,
		},
		data() {
			return {
				loading: false,
				organizationList: [],
				cinemaAllList: [],
				cinemaListFormAdd: [],
				formState: {
					// organizationId: 0,
					cinemaId: 0,
					name: ''
				},
				searchData: {},
				showModal: false,
				modelRef: {},
				selectedRowKeys: [],
				selectedListData: [],
				list: [],
				columns: [{
					title: '影院名称',
					dataIndex: 'cinema'
				}, {
					title: '假期名称',
					dataIndex: 'holiday'
				}, {
					title: '日期',
					key: 'time'
				}, {
					title: '创建时间',
					key: 'createdTime'
				}, {
					title: '操作',
					key: 'action',
					width: 100,
					fixed: 'right'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
				isEdit: false,
				id: 0
			}
		},
		created() {
			this.getOrganizationList();
		},
		methods: {
			onBack() {
				this.showModal = false;
			},
			onSearch() {
				this.pagination.current = 1;
				// //this.pagination.pageSize = 10;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				this.searchData.organizationId = this.searchData.organizationId ? this.searchData.organizationId : undefined;
				this.searchData.cinemaId = this.searchData.cinemaId ? this.searchData.cinemaId : undefined;
				this.getData();
			},
			reset() {
				this.$refs.form.resetFields();
				if (this.organizationList.length) {
					this.formState.organizationId = this.organizationList[0].id;
					this.getAllCinemaList(this.formState.organizationId);
				}
				this.onSearch();
			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getHolidayPolicyList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						...this.searchData
					})
					this.loading = false;
					if (ret.code === 200) {
						this.pagination.total = ret.data.totalCount;
						this.list = ret.data.list;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getOrganizationList() {
				this.loading = true;
				try {
					let ret = await getOrganizationList({
						page: 1,
						pageSize: 999999
					});
					if (ret.code === 200) {
						this.organizationList = ret.data.list;
						if (this.organizationList.length) {
							this.formState.organizationId = this.organizationList[0].id;
							this.getAllCinemaList(this.formState.organizationId);
						}
						this.getData();
					} else {
						this.loading = false;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async getAllCinemaList(organizationId, isAdd) {
				if (isAdd) {
					this.cinemaListFormAdd = [];
					this.modelRef.cinemaId = undefined;
				} else {
					this.cinemaAllList = [];
					this.formState.cinemaId = 0;
				}
				let ret = await getCinemaList({
					page: 1,
					pageSize: 999999,
					organizationId: organizationId ? organizationId : undefined
				})
				if (ret.code === 200) {
					if (isAdd) {
						this.cinemaListFormAdd = ret.data.list;
					} else {
						this.cinemaAllList = ret.data.list;
					}
				}
			},
			onDelete(item) {
				this.$confirm({
					title: '提示',
					content: '确定删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await deleteHolidayPolicy({
								id: item.id
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onBatchDelete() {
				this.$confirm({
					title: '提示',
					content: '确定批量删除吗？',
					onOk: async () => {
						this.loading = true;
						try {
							let ret = await batchDeleteHolidayPolicy({
								ids: this.selectedRowKeys.join(',')
							});
							this.loading = false;
							if (ret.code === 200) {
								this.$message.success('操作成功');
								this.getData();
							}
						} catch(e) {
							this.loading = false;
						}
					}
				})
			},
			onAdd() {
				this.showModal = true;
				this.$nextTick(() => {
					this.modelRef = {};
					this.$refs.addForm.resetFields();
				})
			},
			onSubmit(isRef) {
				this.$refs.addForm.validateFields().then(async () => {
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					postData.startTime = parseInt(this.moment(postData.time[0]).startOf('day').valueOf() / 1000);
					postData.endTime = parseInt(this.moment(postData.time[1]).endOf('day').valueOf() / 1000);
					this.loading = true;
					try {
						let ret = await saveHolidayPolicy(postData);
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							if (isRef) {
								this.showModal = false;
								this.getData();
							} else {
								this.modelRef = {};
								this.$refs.addForm.resetFields();
							}
						}
					} catch(e) {
						this.loading = false;
					}
				})
			},
			onSelectChange(record, selected) {
				if (selected) {
					this.selectedListData.push(JSON.parse(JSON.stringify(record)));
					this.selectedRowKeys.push(record.id);
				} else {
					this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
					this.selectedListData = this.selectedListData.filter(item => {
						return item.id !== record.id;
					});
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.list.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
		}
	}
</script>

<style>
	.ui-form__item {
		margin-right: 30px;
	}
</style>
